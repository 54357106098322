<template>
  <BaseSection
    :id="section.key"
    :is-visible="isSectionVisible"
    :is-modified="isDataChanged"
    :section="section"
    @onSectionSaveClick="save"
  >
    <template #section>
      <v-card-text class="mt-4">
        <div class="mb-5">
          <v-simple-table class="mt-5">
            <template #default>
              <tbody>
                <tr
                  v-for="(loanStructureItem, idx) in loanStructure"
                  :key="idx"
                >
                  <td>
                    <v-text-field
                      dense
                      type="text"
                      v-model="loanStructureItem.loan_emount"
                      placeholder="Loan amount"
                      label="Loan amount"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-combobox
                      v-model="loanStructureItem.term"
                      :items="termOptions"
                      label="Term"
                      class="pa-5"
                      dense
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-text-field
                      dense
                      type="text"
                      v-model="loanStructureItem.rate"
                      placeholder="Rate, % pa"
                      label="Rate, % pa"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-text-field
                      dense
                      type="text"
                      v-model="loanStructureItem.repayment_amount"
                      placeholder="Repayment, $"
                      label="Repayment, $"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-select
                      dense
                      v-model="loanStructureItem.period"
                      :items="periodOptions"
                      label="Period"
                      class="pa-5"
                      :disabled="FIELDS_DISABLED"
                    />
                  </td>

                  <td>
                    <v-icon @click.prevent="removeLoanStructure(idx)" :disabled="FIELDS_DISABLED">
                      mdi-delete
                    </v-icon>
                  </td>
                </tr>
              </tbody>
            </template>
          </v-simple-table>
          <v-btn color="primary" @click="addLoanStructure" :disabled="FIELDS_DISABLED">
            Add new
          </v-btn>
        </div>
      </v-card-text>
    </template>
  </BaseSection>
</template>

<script>
import { mapGetters } from 'vuex';

import { clone } from '@/utils/object';

import SectionMixin from '@/mixins/SectionMixin';

import BaseSection from '@/components/ApplicationSections/BaseSection.vue';

export default {
  name: 'loanStructureSection',

  components: {
    BaseSection,
  },

  mixins: [SectionMixin],

  props: {
    existedData: {
      type: Array,
      default() {
        return [];
      },
    },

    section: {
      type: Object,
      required: true,
    },
  },

  data() {
    return {
      termOptions: ['Fixed 1 year', 'Fixed 1.5 years', 'Fixed 2 years'],
      periodOptions: ['Week', 'Fortnight', 'Month', 'Year'],

      isSectionVisible: false,

      initialLoanStructure: [],
      loanStructure: [],
    };
  },

  computed: {
    ...mapGetters(['FIELDS_DISABLED']),
  },

  async created() {
    await this.checkIsSectionVisible();

    this.initialLoanStructure = clone(this.existedData);
    this.loanStructure = clone(this.existedData);

    this.$emit('componentReady');
  },

  methods: {
    addLoanStructure() {
      const loanStructureData = {
        loadn_amount: '',
        term: '',
        rate: '',
        repayment_amount: '',
        period: '',
      };

      this.loanStructure.push(loanStructureData);
    },

    removeLoanStructure(idxLoanStructure) {
      this.loanStructure.splice(idxLoanStructure, 1);
    },
  },
};
</script>
